import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

export default function Contact() {
  return (
    <Layout page='contact' bg='https://s3.amazonaws.com/flyingpoint/miamibg3.jpg'>
      <Helmet>
        <title>Contact | Flying Point</title>
      </Helmet>
      <h1>Contact</h1>
      <div className="row">
        <div className="col-half">
          <p className="big-text">
            260 95th Street<br />
            Suite 210<br />
            Surfside, FL 33154
          </p>
        </div>
      </div>
      <p className="big-text">
        <a href="mailto:info@flyingpointre.com">info@flyingpointre.com</a><br />
        tel: 305.900.5626
      </p>
    </Layout>
  );
}